@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,600,700&display=swap&subset=latin-ext');

@tailwind base;
@tailwind components;

html {
  @apply font-body bg-blue-100;
}

@screen md {
  .top-menu {
    display: block !important;
  }
}

@layer utilities hover {
  .bg-no-image {
    background-image: none;
  }
}

.microdata {
  display: none !important;
}

@tailwind utilities;
